import React, { useState, useEffect, useContext } from "react"
import { UserContext } from "../../../context/userContext"
import axios from "axios"

// PR
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { RadioButton } from "primereact/radiobutton"
import { SplitButton } from "primereact/splitbutton"

// DataRoom URL
import { dataRoomURL } from "../../../config/cloudFunctionsURL"

// Hooks
import useTriggerDataLayer from "../../../hooks/useTriggerDataLayer"

export default function SaveReport(props) {
  const [reportName, setReportName] = useState("")
  const [projectName, setProjectName] = useState("")
  const [triggerDataLayer] = useTriggerDataLayer()

  const userCtx = useContext(UserContext)

  useEffect(() => {
    if (props.reportName) {
      setReportName(props.reportName)
    }
    if (props.projectName) {
      setProjectName(props.projectName)
    }
  }, [props.reportName, props.projectName])

  const [permission, setPermission] = useState("private")
  const [visible, setVisible] = useState(false)

  const handlePermissionChoice = () => {
    if (userCtx.user_role > 11) {
      return (
        <div className="card flex mt-1">
          <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="private"
                name="private"
                value="private"
                onChange={(e) => setPermission(e.value)}
                checked={permission === "private"}
              />
              <label htmlFor="private" className="ml-2">
                Private
              </label>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="card flex mt-1">
        <div className="flex flex-wrap gap-3">
          <div className="flex align-items-center">
            <RadioButton
              inputId="private"
              name="private"
              value="private"
              onChange={(e) => setPermission(e.value)}
              checked={permission === "private"}
            />
            <label htmlFor="private" className="ml-2">
              Private
            </label>
          </div>

          <div className="flex align-items-center">
            <RadioButton
              inputId="public"
              name="public"
              value="public"
              onChange={(e) => setPermission(e.value)}
              checked={permission === "public"}
            />
            <label htmlFor="public" className="ml-2">
              Public
            </label>
          </div>
        </div>
      </div>
    )
  }

  const handleExportData = async () => {
    const baseUrl = dataRoomURL()
    const currentPath = window.location.pathname
    const splitPath = currentPath.split("/")
    const visualizationType = splitPath[2] || null
    const postBody = props.selectedValues
    try {
      const response = await axios.post(
        `${baseUrl}/api/reports/export-report/${visualizationType}`,
        postBody,
        {
          responseType: "blob",
        }
      )
      const blob = new Blob([response.data], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      const timestamp = new Date().getTime()
      a.download = `report_${timestamp}.csv`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      const dataLayerBody = postBody
      triggerDataLayer("report_export_download", dataLayerBody)
    } catch (error) {
      console.error("Error exporting report data", error)
    }
  }

  const items = [
    {
      label: "Export Data",
      icon: "pi pi-download",
      command: () => handleExportData(),
    },
  ]

  return (
    <>
      <SplitButton
        label="Save Report"
        icon="pi pi-save"
        onClick={() => {
          const dataLayerBody = {
            step: 1,
          }
          triggerDataLayer("report_save", dataLayerBody)
          setVisible(true)
        }}
        model={items}
      />
      <Dialog
        header="Save Report"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 30,
            marginBottom: 45,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="">Report Name:</label>
            <InputText
              placeholder="Report Name"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="">Project Name:</label>
            <InputText
              placeholder="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label for="">Permission:</label>
            {handlePermissionChoice()}
          </div>
        </div>

        <Button
          label="Save"
          className={props.isValidate ? "btn-secondary" : "btn-secondary grey"}
          disabled={!props.isValidate}
          onClick={() => {
            const dataLayerBody = {
              step: 2,
              report_name: reportName,
              project_name: projectName,
            }
            triggerDataLayer("report_save", dataLayerBody)
            props.saveReport(reportName, projectName, permission)
            setVisible(false)
          }}
        />
      </Dialog>
    </>
  )
}
