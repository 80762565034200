import React, { useContext } from "react"
import { getAuth } from "firebase/auth"
import { useNavigate } from "react-router-dom"

import { UserContext } from "../context/userContext"

import logoDefault from "../images/logo_starset_analytics.png"
import logoMMA from "../images/MMA-Primary-Logo-H-RGB-White.webp"

import styles from "../styles/NavMain.module.css"

// Menus
import * as Menu from "./menus/"

export default function MainNav() {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)

  const handleMenuPerRole = () => {
    const role = Number(userCtx.user_role)
    switch (true) {
      case role === 0:
        return <Menu.SysAdmin />
      case role === 1:
        return <Menu.Admin />
      case role > 9:
        return <Menu.Analyst />
      default:
        return null
    }
  }

  const handleLogo = () => {
    const organization = userCtx.organization_alias
    switch (organization) {
      case "mma":
        return (
          <img
            src={logoMMA}
            alt="Marsh & McLennan Agency"
            className={styles.logoMma}
          />
        )
      case "ths":
      case "ths_sandbox":
        return (
          <img
            src={logoDefault}
            alt="Starset Analytics"
            className={styles.logo}
          />
        )
      default:
        return (
          <img
            src={logoDefault}
            alt="Starset Analytics"
            className={styles.logo}
          />
        )
    }
  }

  return (
    <nav className={`nav-main ${styles.nav}`}>
      <div className={styles.secondaryMenu}>
        <ul>
          <li>
            <span className="material-icons">account_circle</span>
            <span className="label">Profile</span>
          </li>
          <li>
            <button
              onClick={async () => {
                await getAuth().signOut()
                navigate("/")
              }}
            >
              <span className="material-icons">logout</span>
              Logout
            </button>
          </li>
        </ul>
      </div>
      <div className={styles.mainMenu}>
        {handleLogo()}
        {/* <img src={logo} alt="logo" className={styles.logo} /> */}
        {handleMenuPerRole()}
      </div>
    </nav>
  )
}
