import React, { useState, useEffect } from "react"
import axios from "axios"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { auth } from "./firebase"

import LoaderFullPage from "./components/Loaders"

// Layout
import Layout from "./layout"

// Pages
import Login from "./pages/Login"

// Routes
import RoutesBuilder from "./components/Routes"

import { GET } from "./config/constants"
import cloudFunctionsURL from "./config/cloudFunctionsURL"

import useTriggerDataLayer from "./hooks/useTriggerDataLayer"

// Context
import { UserContext } from "./context/userContext"

function App() {
  const [user, setUser] = useState(null)
  const [cssLoaded, setCssLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [triggerDataLayer] = useTriggerDataLayer()

  useEffect(() => {
    const getUserProfile = async (authUser) => {
      setIsLoading(true)
      const URL = `${cloudFunctionsURL()}/${GET.USER_PROFILE}`
      try {
        const fetchUserProfile = await axios.post(URL, {
          email: authUser.email,
        })
        const userData = fetchUserProfile.data.data || null
        if (userData && userData.organization_alias) {
          const link = document.createElement("link")
          link.rel = "stylesheet"
          link.type = "text/css"
          link.href = `/styles/${userData.organization_alias}.css`
          link.onload = () => setCssLoaded(true)
          document.head.appendChild(link)
        } else {
          setCssLoaded(true) // If no organization, still allow the app to render
        }
        setUser(userData)
        triggerDataLayer("login", userData)
      } catch (error) {
        setUser(null)
        setCssLoaded(true) // Allow the app to render on error
        console.log("Error getting User Profile.", error)
      } finally {
        setIsLoading(false)
      }
    }

    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (
        authUser &&
        authUser.emailVerified &&
        authUser.multiFactor.enrolledFactors.length > 0
      ) {
        getUserProfile(authUser)
      } else {
        setUser(null)
        setCssLoaded(true) // Allow the app to render if no user is authenticated or email not verified
      }
    })

    return () => unsubscribe()
  }, [])

  const HandleRoutes = () => {
    if (!cssLoaded || isLoading) {
      return <LoaderFullPage />
    }

    if (user) {
      return (
        <UserContext.Provider value={user}>
          <Layout>
            <RoutesBuilder />
          </Layout>
        </UserContext.Provider>
      )
    }
    return (
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    )
  }

  return (
    <Router>
      <HandleRoutes />
    </Router>
  )
}

export default App
