import React, { useState, useContext, useMemo } from "react"
import _ from "lodash"
import axios from "axios"

// Ctx
import { UserContext } from "../../../../context/userContext"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Comps
import FilterBuilder from "../../components/FieldBuilder"
import UserHelpText from "../../components/UserHelpText"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

// Loaders
import { LoaderProgressBar } from "../../../../components/Loaders"

// Styles
import styles from "../../../../styles/DataRoom.module.css"

// HC
import Bar from "./highcharts/Bar"

const baseUrl = dataRoomURL()

export default function CarriersAndFacilitySystems() {
  const [fetching, setFetching] = useState(false)
  const userCtx = useContext(UserContext)

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([]) // TODO: check if this is needed

  // Deprecated
  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8) // TODO: check if this is needed

  // Reports: *******TODO: Remove*******
  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search

  // URL Param Values + Visualization
  const carriedFieldValues = useMemo(() => {
    const urlParams = new URLSearchParams(queryString)
    return {
      cbsa_msa_name: urlParams.get("cbsa_msa_name") ?? "",
      region_value: urlParams.get("region_value") ?? "",
      segment: urlParams.get("segment") ?? "",
      billing_code_category: urlParams.get("billing_code_category") ?? "",
      billing_code_category_value:
        urlParams.get("billing_code_category_value") ?? "",
      report_id: urlParams.get("report_id") ?? null,
      visualization: "pricerate",
    }
  }, [queryString])

  // TODO: Check if this is needed
  const getStoredValues = async () => {
    const { report_id } = carriedFieldValues
    setReportId(report_id)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const handleGenerate = async (selectedValues = {}) => {
    setFetching(true)
    const postBody = {
      ...selectedValues,
      user_role: userCtx.user_role.toString(), // temp until JWT
      data_region: userCtx.data_region.toString(), // temp until JWT
    }
    try {
      const fetchUrl = `${baseUrl}/api/dashboard/provider-price-rate-bar`
      const response = await axios.post(fetchUrl, postBody)
      setPayload(response.data)
      setFetching(false)
    } catch (error) {
      console.log("Error fetching data", error)
      setFetching(false)
    }
  }

  const handleGeomeanTable = () => {
    if (_.isEmpty(geomeandata)) return null

    const groupedData = _.chain(geomeandata)
      .groupBy("billing_code_type_label")
      .map((codeData, code) => ({
        billingCodeTypeLabel: code,
        ..._.mapValues(
          _.keyBy(codeData, "carrier_name"),
          "geomean_negotiated_rate"
        ),
      }))
      .value()

    // Flattening the array of objects into an array of rows
    const dataTableArray = _.flatMap(groupedData, (row) => [row])

    const columns = Object.keys(dataTableArray[0]).map((column) => ({
      field: column,
      header: column,
    }))

    return (
      <DataTable value={dataTableArray}>
        {columns.map((col) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
    )
  }

  const handleChartRender = () => {
    if (fetching) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />
    return <Bar payload={payload} />
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  function HandleHeader() {
    return (
      <div>
        <h1>
          Price Rate Modeling: Projected Total Costs based on
          <span className={handleHideText()}> MMA</span> Regional Utilization
          Data
        </h1>
        <p>
          The dashboard displays comparative projected costs for category
          billing codes by commercial insurance carrier plans within a defined
          geographic Core-Based Statistical Area (CBSA). Loaded in this tool for
          modeling is a basket of top billing codes representing steady state
          utilization in a commercial population derived from{" "}
          <span className={handleHideText()}>MMA Region claims, </span>
          MarketScan Commercial Claims and Encounters Database and the
          Healthcare Cost and Utilization Project.{" "}
          <span className={handleHideText()}>
            Projected costs are derived by multiplying a carrier average
            negotiated rate for a billing code by respective claim counts as
            reported by MMA.
          </span>
        </p>
        <p>
          In general, the chart data aims to inform price and cost modeling
          practices within set geographic regions. All reported billing codes
          reflect institutional class negotiated rates.
        </p>
      </div>
    )
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <HandleHeader />
      <div className={styles.wrapper}>
        <div className="filters">
          <FilterBuilder
            handleGenerate={handleGenerate}
            carriedFieldValues={carriedFieldValues}
            filterGroupId="price-rate-modeling"
            screen={2}
          />
        </div>
        <div className={styles.plot}>
          {handleChartRender()}
          {handleGeomeanTable()}
        </div>
      </div>
    </div>
  )
}
