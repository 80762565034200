import React, { useState, useContext, useMemo } from "react"
import _ from "lodash"
import axios from "axios"

// Ctx
import { UserContext } from "../../../../context/userContext"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Comps
import FilterBuilder from "../../components/FieldBuilder"
import UserHelpText from "../../components/UserHelpText"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

// Loaders
import { LoaderProgressBar } from "../../../../components/Loaders"

// Styles
import styles from "../../../../styles/DataRoom.module.css"

// HC
import Bar from "./highcharts/Bar"

const baseUrl = dataRoomURL()

export default function CommercialInsuranceCarrierNetworkComparisons() {
  const [fetching, setFetching] = useState(false)
  const userCtx = useContext(UserContext)

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([]) // TODO: check if this is needed

  // Deprecated
  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8) // TODO: check if this is needed

  // Reports: *******TODO: Remove*******
  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search

  // Memoize carriedFieldValues
  const carriedFieldValues = useMemo(() => {
    const urlParams = new URLSearchParams(queryString)
    return {
      region: urlParams.get("region") ?? "",
      region_value: urlParams.get("region_value") ?? "",
      procedure_type: urlParams.get("procedure_type") ?? "",
      report_id: urlParams.get("report_id") ?? null,
      visualization: "networkbar",
    }
  }, [queryString])

  // TODO: Check if this is needed
  const getStoredValues = async () => {
    const { report_id } = carriedFieldValues
    setReportId(report_id)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const handleGenerate = async (selectedValues = {}) => {
    setFetching(true)
    const postBody = {
      ...selectedValues,
      user_role: userCtx.user_role.toString(), // temp until JWT
      data_region: userCtx.data_region.toString(), // temp until JWT
    }
    try {
      const fetchUrl = `${baseUrl}/api/dashboard/provider-network-bar`
      const response = await axios.post(fetchUrl, postBody)
      setPayload(response.data)
      setFetching(false)
    } catch (error) {
      console.log("Error fetching data", error)
      setFetching(false)
    }
  }

  const handleGeomeanTable = () => {
    if (_.isEmpty(geomeandata)) return null

    const groupedData = _.chain(geomeandata)
      .groupBy("billing_code_type_label")
      .map((codeData, code) => ({
        billingCodeTypeLabel: code,
        ..._.mapValues(
          _.keyBy(codeData, "carrier_name"),
          "geomean_negotiated_rate"
        ),
      }))
      .value()

    // Flattening the array of objects into an array of rows
    const dataTableArray = _.flatMap(groupedData, (row) => [row])

    const columns = Object.keys(dataTableArray[0]).map((column) => ({
      field: column,
      header: column,
    }))

    return (
      <DataTable value={dataTableArray}>
        {columns.map((col) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
    )
  }

  const handleChartRender = () => {
    if (fetching) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />
    return <Bar payload={payload} />
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <div>
        <h1>
          Network Selection: Commercial Insurance Carrier Normalized Mean Rates
          for IP/OP
        </h1>
        <p>
          The chart displays a normalized average standard deviation of mean
          negotiated rates for all billing codes classified as Inpatient or
          Outpatient procedures. For reporting purposes, mean billing code rates
          for each represented Commercial Insurance Carrier are standardized to
          a z-score to support comparative data.
        </p>
        <p>
          In general, the chart data aims to assist in carrier plan network
          decisions. The data can be interpreted as: On average, the selected
          carrier plan network reports negotiated billing code rates within a
          selected procedure type that are below (more competitive) or above
          (less competitive) the expected market rate averages. This expected
          market average is indicated by the 0-value line (no deviation from
          average rates) on the chart.
        </p>
        <p>
          Loaded in this tool for modeling is a basket of top billing codes
          representing steady state utilization in a commercial population
          derived from
          <span className={handleHideText()}> MMA Region claims,</span>{" "}
          MarketScan Commercial Claims and Encounters Database and the
          Healthcare Cost and Utilization Project.
        </p>
      </div>
      <div className={styles.wrapper}>
        <div className="filters">
          <FilterBuilder
            handleGenerate={handleGenerate}
            carriedFieldValues={carriedFieldValues}
            filterGroupId="network-selection"
            screen={2}
          />
        </div>
        <div className={styles.plot}>
          {handleChartRender()}
          {handleGeomeanTable()}
        </div>
      </div>
    </div>
  )
}
