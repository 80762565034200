import React from "react"

export default function UserHelpText() {
  return (
    <div className="p-3" style={{ width: "80%" }}>
      <h3 className="flex gap-2">
        <i className="pi pi-exclamation-circle" style={{ fontSize: "2rem" }} />
        Make new selections in each of the available filter options and then
        select Visualize to refresh your plot display.
      </h3>
      <p>
        If you wish to change your segment view (View Rates By: Commercial
        Insurance Carriers, Facility Provider System, or Facility Provider
        Type), select <strong>Reset.</strong>
      </p>
      <p>
        Select <strong>Save Report</strong> for any plot display if you wish to
        download data or bookmark the plot for future reference. Saved reports
        will be listed on your Workbench page.
      </p>
    </div>
  )
}
